.blue {
  background-color: #33a4c6;
}

.hidden {
  display: none;
}

.work-list-item {
  position: relative;
  display: block;
  height: 45px;
  margin-bottom: 5px;
  background-color: #fff;
  border: 1px solid #337ab7;
  border-radius: 4px;
}

.work-list-item * {
  color: #337ab7;
}

.work-list-item-score {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.work-list-item:not(.unclickable-work-list-item):hover {
  background-color: rgb(220, 229, 240);
  cursor: pointer;
}

.unclickable-work-list-item {
  color: #666666;
  background-color: #dddddd;
  border-color: #666666;
}

.view-work-history {
  color: #e4e7eb;
  text-align: center;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #005a99;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  font-weight: bold;
}

.view-work-history:hover {
  color: white;
  cursor: pointer;
  background-color: #4a5c6d;
  border-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.limit-list-height {
  overflow-y: auto;
  max-height: 340px;
}

@media (max-height: 415px) {
  .limit-list-height {
    max-height: 190px;
  }
}

.homework-done-circle {
  display: 'flex';
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
