/* Ensure the html and body elements take up full height */
html,
body,
#react-app {
  height: 100%;
}

div {
  font-family: 'Inter', Arial, sans-serif;
}

div,
body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.no-select {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below may not be implemented in  browsers yet */
  -o-user-select: none;
  user-select: none;
  cursor: default;
}

.hidden {
  display: none;
}

#hamburger {
  height: 30px;
}

.sticky-banner {
  position: fixed;
  background-color: white;
  top: 0;
  width: 100%;
}

.need-help-disabled-popup {
  position: absolute;
  width: 200px;
  top: 0px;
  right: 0px;
  background-color: #797676;
  color: white;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
}

.center {
  position: absolute;
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

/* Customize scrollbar appearance for WebKit browsers (like Safari and Chrome) */
.scrollable::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: content-box;
}

.scrollable::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.test-valid {
  background-color: gray;
  border-color: gray;
}

.test-untried {
  background-color: white;
  border-color: lightgray;
  border-style: solid;
  border-width: 4px;
}
