.level0 {
  background-color: white;
}

.level1 {
  background-color: rgb(241, 98, 99);
}

.level2 {
  background-color: rgb(232, 219, 60);
  /* background-color: rgb(190, 190, 0)  */
}

.level3 {
  background-color: rgb(116, 192, 81);
}

.level4 {
  background-color: rgb(35, 35, 35);
  color: rgb(192, 192, 192);
}

.level5 {
  background-color: rgb(35, 35, 35);
  color: rgb(232, 219, 60);
}

.test-locked {
  background-color: gray;
  border-color: gray;
}

.magentaback {
  background-color: magenta;
  border-color: magenta;
}

.box {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 36px;
  justify-content: center;
  perspective: 1000;
  width: 36px;
  position: relative;
}

.innerBox {
  height: 24px;
  width: 24px;
  position: absolute;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  will-change: transform;
}

.box-selected {
  border-color: #33a4c6;
  border-style: solid;
  border-width: 3px;
}

.triangle-topleft {
  width: 0;
  height: 0;
  position: absolute;
  border-top: 10px solid rgb(243, 166, 30);
  border-right: 10px solid transparent;
}

.triangle-topright {
  width: 0;
  height: 0;
  position: absolute;
  right: 0px;
  border-top: 10px solid rgb(243, 166, 30);
  border-left: 10px solid transparent;
}

.triangle-bottomleft {
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0px;
  border-bottom: 10px solid rgb(243, 166, 30);
  border-right: 10px solid transparent;
}

.triangle-bottomright {
  width: 0;
  height: 0;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-bottom: 10px solid rgb(243, 166, 30);
  border-left: 10px solid transparent;
}

/* added without verification, presumably part of level up animation */
.boxShine {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  inset: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
}
.boxShine::before,
.boxShine::after {
  content: '';
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  transform: translateX(var(--translate-x)) skewX(-15deg);
}
.boxShine::before {
  background: rgba(255, 255, 255, 0.7);
  filter: blur(5px);
  width: 10px;
}
.boxShine::after {
  background: rgba(255, 255, 255, 0.4);
  filter: blur(2.5px);
  width: 5px;
}
